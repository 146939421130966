<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="d-flex justify-space-between">
            <span>Создать команду</span>

            <VBtn
              depressed
              color="primary"
              :to="{
                name : Names.R_ORGSTRUCTURE_COMPANY,
                params : {
                  id : companyId
                }
              }"
            >
              <VIcon left>
                fal fa-chevron-left
              </VIcon>

              Вернуться к списку команд
            </VBtn>
          </VCardTitle>

          <VDivider />

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VForm>
                  <VTextField
                    v-model="newTeam.name"
                    label="Название команды"
                    filled
                  />

                  <VTextField
                    v-model="newTeam.displayName"
                    label="Отображаемое имя"
                    filled
                  />

                  <VTextField
                    v-model="newTeam.teamType"
                    label="teamType"
                    filled
                    persistent-hint
                    hint="дополнительное поле"
                  />

                  <VTextField
                    v-model="newTeam.externalId"
                    label="externalId"
                    filled
                    persistent-hint
                    hint="дополнительное поле"
                  />
                </VForm>
              </VCol>
            </VRow>
          </VCardText>

          <VCardActions class="px-4">
            <VBtn
              depressed
              color="primary"
              x-large
              @click="createTeam"
            >
              Создать
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'AddTeamToCompanyForm',

  inject: ['Names'],

  data() {
    return {
      newTeam: {
        name: '',
        displayName: '',
        teamType: '',
        externalId: '',
      },
    };
  },

  computed: {
    companyId() {
      return this.$route.params.id;
    },
  },

  methods: {
    createTeam() {
      const {
        name, displayName, teamType, externalId,
      } = this.newTeam;
      const { companyId } = this;
      this.$di.api.Orgstructure
        .teamCreate({
          name,
          displayName,
          teamType,
          externalId,
          companyId,
        })
        .then(() => {
          this.$di.notify.snackSuccess('Команда создана');
          this.cancel();
        })
        .catch(this.$di.notify.errorHandler);
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
